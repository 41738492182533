
import { Component, Vue, Watch } from "vue-property-decorator";
import IndividualRegistration from "@/components/Commodity Accounts/Individual/Registration.vue";
import ClosedCorpRegistration from "@/components/Commodity Accounts/ClosedCorp/Registration.vue";
import PartnershipRegistration from "@/components/Commodity Accounts/Partnership/Registration.vue";
import TrustsRegistration from "@/components/Commodity Accounts/Trusts/Registration.vue";
import PrivateRegistration from "@/components/Commodity Accounts/Private/Registration.vue";
import {
  ClientAccount,
  ClientOverview,
  ClientOverviewSubAccount,
  UserAccessModel,
  VerifyClient,
} from "models";
import User from "@/store/modules/user";
import dayjs from "dayjs";
import Hooks from "@/hooks";
import SafexDivision from "@/hooks/safexDivision";
import { EnumStatus, VerifiedClientEnum } from "@/enum";

const {
  ClientAccounts,
  ClosedCorpEntityDetails,
  TrustAccount,
  PartnershipAccount,
  PrivateAccount,
  WelcomeLetter,
  JSEClient,
} = Hooks;
const clientAccountHook = ClientAccounts();
const closedCorpAccountHook = ClosedCorpEntityDetails();
const trustAccountHook = TrustAccount();
const partnershipAccountHook = PartnershipAccount();
const privateAccountHook = PrivateAccount();
const wlHook = WelcomeLetter();

@Component({
  components: {
    IndividualRegistration,
    ClosedCorpRegistration,
    PartnershipRegistration,
    TrustsRegistration,
    PrivateRegistration,
  },
  filters: {
    convertDate: function (value: string) {
      return dayjs(value).format("YYYY-MM-DD");
    },
  },
})
export default class Overview extends Vue {
  items: ClientOverview[] = [];
  tab = "approved";
  filteredItems: ClientOverview[] = [];
  loading = false;
  search = "";
  sortDesc = false;
  sortBy = "genericCode";
  filter: string | null = null;
  expanded = [];
  accountModal = false;
  wlModal = false;
  wlLanguage = "English";
  wlItem: ClientOverview | null = null;
  summaryModal = false;
  summaryItem: ClientOverview | undefined | null = null;
  summaryLoading = false;
  /*  deleteModal = false;
  deleteItem: ClientOverview | null = null;
  deleteLoading = false; */
  reinstateModal = false;
  reinstateItem: ClientOverview | null = null;
  reinstateLoading = false;
  individualStatusModal = false;
  individualStatusItem: ClientOverview | null = null;
  individualStatusValueToSubmit: "Approved" | "Suspended" | "Awaiting" | null =
    null;
  individualStatusLoading = false;
  authorised = true;
  accounts: {
    account: string;
    color: string;
  }[] = [
    {
      account: "Individual",
      color: "primary",
    },
    {
      account: "Closed Corporation (CC)",
      color: "secondary",
    },
    {
      account: "Private Company",
      color: "accent",
    },
    {
      account: "Partnership",
      color: "primary",
    },
    {
      account: "Trust",
      color: "secondary",
    },
  ];
  individualModal = false;
  closedCorpModal = false;
  privateModal = false;
  partnershipModal = false;
  trustsModal = false;
  modalType: string | null = null;
  refresh = false;
  divisionToBeEdited: {
    ircCode: string | null;
    safexCode: string | null;
    physicalsCode: string | null;
    subAccount: string | null;
  } = {
    ircCode: null,
    safexCode: null,
    physicalsCode: null,
    subAccount: null,
  };
  activeOverviewAccount: ClientOverview | null = null;
  headers = [
    { text: "Client Code", value: "genericCode" },
    { text: "Branch", value: "branch" },
    { text: "Principal", value: "safexCode" },
    { text: "Physicals Code", value: "physicCode" },
    { text: "JSE Code", value: "ircCode" },
    { text: "Main Account", value: "subAccount" },
    { text: "Name", value: "clientName" },
    { text: "Email", value: "email" },
    { text: "Cell Phone No", value: "cell" },
    { text: "", value: "actions", sortable: false },
  ];
  keys = [
    { text: "Client Code", value: "genericCode" },
    { text: "Safex Code", value: "safexCode" },
    { text: "Physicals Code", value: "physicCode" },
    { text: "JSE Code", value: "ircCode" },
    { text: "Sub Account", value: "subAccount" },
    { text: "Name", value: "clientName" },
    { text: "Branch", value: "branch" },
  ];
  filterKeys = [
    { text: "Individual Accounts", value: "CRM" },
    { text: "Closed Corp Accounts", value: "CC" },
    { text: "Private Company Accounts", value: "PC" },
    { text: "Trust Accounts", value: "TT" },
    { text: "Partnership Accounts", value: "PP" },
  ];

  @Watch("refresh", { immediate: true })
  async modalDataChanged(): Promise<void> {
    if (this.refresh == true) {
      console.log("Refresh Triggered");
      await this.init();
    }
  }

  @Watch("tab", { immediate: false })
  async tabChanged(): Promise<void> {
    console.log("Tab: ", this.tab);
    localStorage.setItem("ClientFile/tab", this.tab);
    await this.init();
  }

  @Watch("individualModal", { immediate: true })
  individualModalChanged(): void {
    if (this.individualModal == false) {
      this.modalType = null;
    }
  }

  @Watch("closedCorpModal", { immediate: true })
  closedCorpModalChanged(): void {
    if (this.closedCorpModal == false) {
      this.modalType = null;
    }
  }

  @Watch("privateModal", { immediate: true })
  privateModalChanged(): void {
    if (this.privateModal == false) {
      this.modalType = null;
    }
  }

  @Watch("partnershipModal", { immediate: true })
  partnershipModalChanged(): void {
    if (this.partnershipModal == false) {
      this.modalType = null;
    }
  }

  @Watch("trustsModal", { immediate: true })
  trustsModalChanged(): void {
    if (this.trustsModal == false) {
      this.modalType = null;
    }
  }

  @Watch("summaryModal", { immediate: true })
  summaryModalDataChanged(): void {
    if (this.summaryModal == true) {
      this.openSummary();
    }
  }

  @Watch("filter", { immediate: false })
  async filterChanged(): Promise<void> {
    await this.init();
  }

  get getUserAccess(): UserAccessModel | null {
    console.log("UA: ", User.getUserAccess);
    return User.getUserAccess;
  }

  get getHeaders(): { text: string; value: string }[] {
    var access: boolean;
    var headers: { text: string; value: string }[] = [];

    if (this.getUserAccess != null) {
      access = this.getUserAccess.viewGenericCodes;
    } else {
      access = true;
    }

    if (access == true) {
      headers = this.headers;
      return headers;
    } else {
      this.keys = [
        { text: "Safex Code", value: "safexCode" },
        { text: "Physicals Code", value: "physicCode" },
        { text: "JSE Code", value: "ircCode" },
        { text: "Sub Account", value: "subAccount" },
        { text: "Name", value: "clientName" },
        { text: "Branch", value: "branch" },
      ];
      return (headers = [
        { text: "Branch", value: "branch" },
        { text: "Safex Code", value: "safexCode" },
        { text: "Physicals Code", value: "physicCode" },
        { text: "JSE Code", value: "ircCode" },
        { text: "Sub Account", value: "subAccount" },
        { text: "Name", value: "clientName" },
        { text: "Email", value: "email" },
        { text: "Cell Phone No", value: "cell" },
        { text: "", value: "actions" },
      ]);
    }
  }

  refreshBrowserTab(): void {
    window.location.reload();
  }

  openWelcomeLetterModal(i: ClientOverview): void {
    this.wlItem = i;
    console.log("Welcome Letter Item: ", this.wlItem);
    this.wlModal = true;
  }

  openReinstateAccount(i: ClientOverview): void {
    this.reinstateItem = i;
    console.log("reinstateItem: ", this.reinstateItem);
    this.reinstateModal = true;
  }

  openIndividualStatusChange(i: ClientOverview) {
    this.individualStatusItem = i;
    console.log("individualStatusItem", this.individualStatusItem);
    this.individualStatusModal = true;
  }

  async applyIndividualStatusChange(): Promise<void> {
    try {
      if (
        this.individualStatusItem &&
        this.individualStatusValueToSubmit != null
      ) {
        let code: string | null = null;
        if (this.individualStatusItem.safexCode != null) {
          code = this.individualStatusItem.safexCode;
        } else if (this.individualStatusItem.ircCode != null) {
          code = this.individualStatusItem.ircCode;
        } else if (this.individualStatusItem.physicCode != null) {
          code = this.individualStatusItem.physicCode;
        }

        if (code != null) {
          const resp = await SafexDivision().UpdateSafexCode(
            code,
            undefined,
            this.individualStatusValueToSubmit == "Approved"
              ? EnumStatus.Approved
              : this.individualStatusValueToSubmit == "Awaiting"
              ? EnumStatus.Awaiting
              : this.individualStatusValueToSubmit == "Suspended"
              ? EnumStatus.Suspended
              : undefined
          );

          this.closeIndividualStatus();
          await this.init();

          this.$notificationCreator.createSuccessNotification(
            "Status Changed Successfully."
          );
        } else {
          this.$notificationCreator.createErrorNotification(
            "Something went wrong while correct determining the account."
          );
        }
      }
      return Promise.resolve();
    } catch (err: any) {
      return Promise.reject(err);
    }
  }

  async reinstateAccount() {
    try {
      this.reinstateLoading = true;

      if (this.reinstateItem) {
        const resp = await SafexDivision().ReActivateSafexCode(
          this.reinstateItem.safexCode
        );
        this.$notificationCreator.createSuccessNotification(
          `${this.reinstateItem.safexCode} successfully reinstated.`
        );
        this.closeReinstate();
        await this.init();
      } else {
        this.$notificationCreator.createErrorNotification(
          "Something went wrong while reinstating account. If this problem persists, please contact the system administrators."
        );
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.reinstateLoading = false;
    }
  }

  /* openDeleteModal(i: ClientOverview): void {
    this.deleteItem = i;
    this.deleteModal = true;
  }

  closeDeleteModal(): void {
    this.deleteItem = null;
    this.deleteModal = false;
  } */

  checkFilter(items: ClientOverview[]): ClientOverview[] {
    items = items.filter((elem) => {
      if (this.filter != null) {
        return elem.genericCode.includes(this.filter);
      }
    });
    return items;
  }

  checkTab(items: ClientOverview[]): ClientOverview[] {
    switch (this.tab) {
      case "awaiting":
        items = items.filter(
          (elem) => elem.enumStatus == 1 && elem.jseClientStatus != 2
        );
        if (this.filter != null) {
          var filteredAwaiting = this.checkFilter(items);
          return filteredAwaiting;
        } else {
          return items;
        }
      case "approved":
        items = items.filter(
          (elem) => elem.enumStatus == 2 && elem.jseClientStatus != 2
        );
        if (this.filter != null) {
          var filteredApproved = this.checkFilter(items);
          return filteredApproved;
        } else {
          return items;
        }
      case "suspended":
        items = items.filter(
          (elem) => elem.enumStatus == 3 && elem.jseClientStatus != 2
        );
        if (this.filter != null) {
          var filteredSuspended = this.checkFilter(items);
          return filteredSuspended;
        } else {
          return items;
        }
      case "closed":
        items = items.filter((elem) => elem.jseClientStatus == 2);
        if (this.filter != null) {
          var filteredClosed = this.checkFilter(items);
          return filteredClosed;
        } else {
          return items;
        }
      default:
        return items;
    }
  }

  openAdd(): void {
    this.modalType = "add";
    this.accountModal = true;
  }

  openAccount(i: string): void {
    this.accountModal = false;
    console.log("Modal opened: ", i);
    switch (i) {
      case "Individual":
        this.individualModal = true;
        break;
      case "Closed Corporation (CC)":
        this.closedCorpModal = true;
        break;
      case "Private Company":
        this.privateModal = true;
        break;
      case "Partnership":
        this.partnershipModal = true;
        break;
      case "Trust":
        this.trustsModal = true;
        break;
    }
  }

  openEdit(i: ClientOverviewSubAccount): void {
    console.log(i);
    this.modalType = "edit";
    localStorage.setItem("ClientFile/entityId", i.genericCode);
    this.divisionToBeEdited.ircCode = i.ircCode;
    this.divisionToBeEdited.safexCode = i.safexCode;
    this.divisionToBeEdited.physicalsCode = i.physicCode;
    this.divisionToBeEdited.subAccount = i.subAccount;
    this.activeOverviewAccount = {
      cell: i.cell,
      clientName: i.clientName,
      email: i.email,
      enumStatus: i.enumStatus,
      genericCode: i.genericCode,
      ircCode: i.ircCode,
      jseTrader: i.jseTrader,
      physicalTrader: i.physicalTrader,
      physicCode: i.physicCode,
      safexCode: i.safexCode,
      safexTrader: i.safexTrader,
      jseClientStatus: i.jseClientStatus,
    };
    if (i.genericCode.includes("CRM")) {
      this.individualModal = true;
    } else if (i.genericCode.includes("CC")) {
      this.closedCorpModal = true;
    } else if (i.genericCode.includes("PC")) {
      this.privateModal = true;
    } else if (i.genericCode.includes("TT")) {
      this.trustsModal = true;
    } else if (i.genericCode.includes("PP")) {
      this.partnershipModal = true;
    }
  }

  async openSummary(): Promise<void> {
    this.summaryLoading = true;
    var entityId: string | null = null;
    const key = localStorage.getItem("ClientFile/entityId");
    if (key != null) {
      entityId = key;
    }

    var account: ClientOverview[] | null = null;
    if (entityId != null) {
      if (entityId.includes("CRM")) {
        account = await this.getIndividuals();
      } else if (entityId.includes("CC")) {
        account = await this.getClosedCorp();
      } else if (entityId.includes("PC")) {
        account = await this.getPrivateCompanies();
      } else if (entityId.includes("TT")) {
        account = await this.getTrusts();
      } else if (entityId.includes("PP")) {
        account = await this.getPartnerships();
      }
    }

    if (account != null) {
      this.summaryItem = account.find((elem) => elem.genericCode == entityId);
    }
    this.summaryLoading = false;
  }

  closeSummary(): void {
    localStorage.removeItem("ClientFile/entityId");
    this.summaryModal = false;
    this.refreshBrowserTab();
  }

  closeReinstate(): void {
    this.reinstateModal = false;
    this.reinstateItem = null;
  }

  closeIndividualStatus() {
    this.individualStatusModal = false;
    if (this.individualStatusLoading == true) {
      this.individualStatusLoading = false;
    }
    this.individualStatusValueToSubmit = null;
    this.individualStatusItem = null;
  }

  async mounted(): Promise<void> {
    const key = localStorage.getItem("ClientFile/tab");
    if (key != null) {
      this.tab = key;
    }
    await this.init();
  }

  async verifyRestrictJSEClient(
    item: ClientOverview,
    verifyRestrict: "v" | "r"
  ): Promise<void> {
    try {
      this.loading = true;

      const existingClient = await JSEClient().getClientDetailsForUpdate(
        item.safexCode
      );

      console.log("existing client ", existingClient);

      if (
        existingClient &&
        existingClient.dealer &&
        existingClient.member &&
        existingClient.clientSeq
      ) {
        const payload: VerifyClient = {
          clientCode: item.safexCode,
          clientSeq: existingClient.clientSeq,
          dealer: existingClient.dealer,
          member: existingClient.member,
          status: verifyRestrict == "v" ? true : false,
        };

        const resp = await JSEClient().verifyOrRestrictClient(payload);
        await this.init();
        this.$notificationCreator.createSuccessNotification(
          verifyRestrict == "v"
            ? "Verified"
            : "Restricted" + " client successful."
        );
      } else {
        this.$notificationCreator.createWarningNotification(
          "Unable to " +
            (verifyRestrict == "v" ? " verify" : " restrict") +
            "client. The client does not exist in the JSE's records"
        );
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
  }

  async getPDF(account: string): Promise<void> {
    try {
      var switchLetter = 0;
      switch (this.wlLanguage) {
        case "Afrikaans":
          if (account == "safex") {
            switchLetter = 1;
          } else if (account == "jse") {
            switchLetter = 3;
          } else if (account == "physicals") {
            switchLetter = 5;
          } else if (account == "absaSafex") {
            switchLetter = 6;
          } else if (account == "absajse") {
            switchLetter = 7;
          }
          break;
        case "English":
          if (account == "safex") {
            switchLetter = 2;
          } else if (account == "jse") {
            switchLetter = 4;
          } else if (account == "physicals") {
            switchLetter = 5;
          } else if (account == "absaSafex") {
            switchLetter = 6;
          } else if (account == "absajse") {
            switchLetter = 7;
          }
          break;
      }

      var logoId = 0;
      if (switchLetter == 5) {
        logoId = 2;
      } else {
        logoId = 1;
      }

      if (this.wlItem != null) {
        var res: any | null = null;
        if (this.wlItem.genericCode.includes("CRM")) {
          res = await clientAccountHook.getClientAccountByClientId(
            this.wlItem.genericCode
          );
          await this.getCRMWelcomeLetter(
            res.id,
            this.wlItem.genericCode,
            switchLetter,
            this.wlItem.ircCode,
            this.wlItem.physicCode,
            this.wlItem.safexCode,
            logoId
          );
        } else if (this.wlItem.genericCode.includes("CC")) {
          res = await closedCorpAccountHook.getClosedCorpByCode(
            this.wlItem.genericCode
          );
          await this.getCCWelcomeLetter(
            res._id,
            this.wlItem.genericCode,
            switchLetter,
            this.wlItem.ircCode,
            this.wlItem.physicCode,
            this.wlItem.safexCode,
            logoId
          );
        } else if (this.wlItem.genericCode.includes("PC")) {
          res = await privateAccountHook.getPrivateCompAccountByCode(
            this.wlItem.genericCode
          );
          await this.getPCWelcomeLetter(
            res._id,
            this.wlItem.genericCode,
            switchLetter,
            this.wlItem.ircCode,
            this.wlItem.physicCode,
            this.wlItem.safexCode,
            logoId
          );
        } else if (this.wlItem.genericCode.includes("TT")) {
          res = await trustAccountHook.getTrustAccountByCode(
            this.wlItem.genericCode
          );
          await this.getTTWelcomeLetter(
            res._id,
            this.wlItem.genericCode,
            switchLetter,
            this.wlItem.ircCode,
            this.wlItem.physicCode,
            this.wlItem.safexCode,
            logoId
          );
        } else if (this.wlItem.genericCode.includes("PP")) {
          res = await partnershipAccountHook.getPartnershipAccountByCode(
            this.wlItem.genericCode
          );
          await this.getPPWelcomeLetter(
            res._id,
            this.wlItem.genericCode,
            switchLetter,
            this.wlItem.ircCode,
            this.wlItem.physicCode,
            this.wlItem.safexCode,
            logoId
          );
        }
      }
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getCRMWelcomeLetter(
    id: number,
    genericCode: string,
    switchLetter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<void> {
    try {
      const resPDF = await wlHook
        .getPermitPDF(
          id,
          genericCode,
          switchLetter,
          jseCode,
          physicalsCode,
          safexCode,
          logoId
        )
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: response.type,
            })
          );
          window.open(url);
        })
        .catch((err: any) => {
          return Promise.reject(err);
        });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getCCWelcomeLetter(
    id: number,
    genericCode: string,
    switchLetter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<void> {
    try {
      const resPDF = await wlHook
        .getWelcomeCCPDF(
          id,
          genericCode,
          switchLetter,
          jseCode,
          physicalsCode,
          safexCode,
          logoId
        )
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: response.type,
            })
          );
          window.open(url);
        })
        .catch((err: any) => {
          return Promise.reject(err);
        });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPCWelcomeLetter(
    id: number,
    genericCode: string,
    switchLetter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<void> {
    try {
      const resPDF = await wlHook
        .getWelcomePCPDF(
          id,
          genericCode,
          switchLetter,
          jseCode,
          physicalsCode,
          safexCode,
          logoId
        )
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: response.type,
            })
          );
          window.open(url);
        })
        .catch((err: any) => {
          return Promise.reject(err);
        });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getPPWelcomeLetter(
    id: number,
    genericCode: string,
    switchLetter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<void> {
    try {
      const resPDF = await wlHook
        .getWelcomePartnerPDF(
          id,
          genericCode,
          switchLetter,
          jseCode,
          physicalsCode,
          safexCode,
          logoId
        )
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: response.type,
            })
          );
          window.open(url);
        })
        .catch((err: any) => {
          return Promise.reject(err);
        });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getTTWelcomeLetter(
    id: number,
    genericCode: string,
    switchLetter: number,
    jseCode: string | null,
    physicalsCode: string | null,
    safexCode: string | null,
    logoId: number
  ): Promise<void> {
    try {
      const resPDF = await wlHook
        .getWelcomeTrustPDF(
          id,
          genericCode,
          switchLetter,
          jseCode,
          physicalsCode,
          safexCode,
          logoId
        )
        .then((response: any) => {
          const url = window.URL.createObjectURL(
            new Blob([response], {
              type: response.type,
            })
          );
          window.open(url);
        })
        .catch((err: any) => {
          return Promise.reject(err);
        });
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async getIndividuals(): Promise<ClientOverview[]> {
    var access: boolean;
    var items: ClientOverview[] = [];

    if (this.getUserAccess != null) {
      access = this.getUserAccess.viewIndividuals;
    } else {
      access = true;
    }

    if (access == true) {
      try {
        const res = await clientAccountHook.getOverview();
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    } else {
      return items;
    }
  }

  async getClosedCorp(): Promise<ClientOverview[]> {
    var access: boolean;
    var items: ClientOverview[] = [];

    if (this.getUserAccess != null) {
      access = this.getUserAccess.viewClosedCorp;
    } else {
      access = true;
    }

    if (access == true) {
      try {
        const res = await closedCorpAccountHook.getOverview();
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    } else {
      return items;
    }
  }

  async getPrivateCompanies(): Promise<ClientOverview[]> {
    var access: boolean;
    var items: ClientOverview[] = [];

    if (this.getUserAccess != null) {
      access = this.getUserAccess.viewPrivateCompany;
    } else {
      access = true;
    }

    if (access == true) {
      try {
        const res = await privateAccountHook.getPCOverview();
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    } else {
      return items;
    }
  }

  async getTrusts(): Promise<ClientOverview[]> {
    var access: boolean;
    var items: ClientOverview[] = [];

    if (this.getUserAccess != null) {
      access = this.getUserAccess.viewTrusts;
    } else {
      access = true;
    }

    if (access == true) {
      try {
        const res = await trustAccountHook.getTTOverview();
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    } else {
      return items;
    }
  }

  async getPartnerships(): Promise<ClientOverview[]> {
    var access: boolean;
    var items: ClientOverview[] = [];

    if (this.getUserAccess != null) {
      access = this.getUserAccess.viewPartnership;
    } else {
      access = true;
    }

    if (access == true) {
      try {
        const res = await partnershipAccountHook.getPPOverview();
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    } else {
      return items;
    }
  }

  async init(): Promise<void> {
    try {
      this.loading = true;
      this.items = [];

      var tempItems: ClientOverview[] = [];

      const resI = await this.getIndividuals();
      resI.forEach((elem) => {
        tempItems.push(elem);
      });
      const resCC = await this.getClosedCorp();
      resCC.forEach((elem) => {
        tempItems.push(elem);
      });
      const resPC = await this.getPrivateCompanies();
      resPC.forEach((elem) => {
        tempItems.push(elem);
      });
      const resTT = await this.getTrusts();
      resTT.forEach((elem) => {
        tempItems.push(elem);
      });
      const resPP = await this.getPartnerships();
      resPP.forEach((elem) => {
        tempItems.push(elem);
      });

      this.items = this.checkTab(tempItems);
      this.items.forEach((elem: any) => {
        if (elem.safexCode != null) {
          elem.branch = elem.safexTrader;
        } else if (elem.ircCode != null) {
          elem.branch = elem.jseTrader;
        } else if (elem.physicCode != null) {
          elem.branch = elem.physicalTrader;
        } else {
          elem.branch = "";
        }
        /* awaiting JSE code to be assigned */
        if (
          elem.safexCode == null &&
          elem.ircCode == null &&
          elem.physicCode == null
        ) {
          elem.branch = elem.safexTrader;
        }

        if (elem.accountingSubAccount != null) {
          elem.subAccount = elem.accountingSubAccount;
        } else if (elem.safexSubAccount != null) {
          elem.subAccount = elem.safexSubAccount;
        }
      });
    } catch (err: any) {
      return Promise.reject(err);
    } finally {
      this.loading = false;
    }
    console.log("Items", this.items);
  }

  /* async deleteCommodityAccount(): Promise<void> {
    try {
      this.deleteLoading = true; */

  /*  if (this.deleteItem) {
        if (this.deleteItem.genericCode.includes("CRM")) {
          const CRMtoDelete: ClientAccount = {
            _id,
            accountName,
            branchTrader,
            contactNumber,
            contactPerson,
            contactPersonEmail,
            country,
            createdBy,
            createdDate,
            dateOfRegistration,
            deleted_At,
            email,
            entityId,
            enumStatus,
            id,
            ranchTrader,
            updatedBy,
            updatedDate,
          };
          const resCRMdel = await clientAccountHook.deleteClientAccount();
        } else if (this.deleteItem.genericCode.includes("CC")) {
          const resCCdel = await closedCorpAccountHook.deleteClosedCorp();
        } else if (this.deleteItem.genericCode.includes("PC")) {
          const resPCdel = await privateAccountHook.deletePrivateCompAccount();
        } else if (this.deleteItem.genericCode.includes("TT")) {
          const resTTdel = await trustAccountHook.deleteTrustAccount();
        } else if (this.deleteItem.genericCode.includes("PP")) {
          const resPPdel =
            await partnershipAccountHook.deletePartnershipAccount();
        }
      } */

  /*   return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.deleteLoading = false;
    }
  } */
}
