import { EnumStatus, VerifiedClientEnum } from "@/enum";
import { axiosInstance } from "@/plugins/axios";
import { SafexDivision } from "models";

const SafexDivision = () => {
  const baseUrl = "/api/SafexDivision";

  const getAllDivisions = async (): Promise<SafexDivision[]> => {
    const res = await axiosInstance.get(baseUrl + `/getAllDivsions`);
    return Promise.resolve(res.data);
  };

  const UpdateSafexCode = async (
    safexCode: string,
    clientStatus?: VerifiedClientEnum,
    enumStatus?: EnumStatus
  ): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl +
        `/UpdateSafexCode?safexCode=${safexCode}${
          clientStatus ? "&clientStatus=" + clientStatus : ""
        }${enumStatus ? "&enumStatus=" + enumStatus : ""}`
    );
    return Promise.resolve(res.data);
  };

  const ReActivateSafexCode = async (safexCode: string): Promise<any> => {
    const res = await axiosInstance.get(
      baseUrl + `/ReActivateSafexCode?safexCode=${safexCode}`
    );
    return Promise.resolve(res.data);
  };

  return {
    getAllDivisions,
    UpdateSafexCode,
    ReActivateSafexCode,
  };
};
export default SafexDivision;
