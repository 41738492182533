import store from "@/store";
import {
  ClientDivision_CREATE,
  CountryCode,
  JSENewSafexModelcs,
  SafexDivision,
  UpdateClient,
} from "models";
import Hooks from "@/hooks";
import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import _Vue from "vue";

interface Division extends ClientDivision_CREATE {
  branch: string;
  dealer: string;
}

const { JSEClient } = Hooks;
const JSEClientHook = JSEClient();

@Module({ dynamic: true, namespaced: true, name: "JSEClients", store })
class JSEClients extends VuexModule {
  addClient = true;
  entityId: string | null = null;
  accountDetails: {
    accountName: string;
    email: string;
    contactNumber: string;
    contactPerson: string;
    contactPersonEmail: string;
    country: string;
    dateOfRegistration: string;
    staffAccount: boolean;
  } | null = null;
  divisions: Division[] = [];
  divisionToBeEdited: {
    ircCode: string;
    safexCode: string;
    physicalsCode: string;
    subAccount: string;
  } | null = null;
  accountContact: {
    name: string | null;
    surname: string | null;
    dateOfBirth: string | null;
    email: string | null;
    cellNumber: string | null;
    isCompany: boolean | null;
    companyAddress: string | null;
    postalAddress: string | null;
    streetAddress: string | null;
    streetAddress2: string | null;
    suburb: string | null;
    city: string | null;
    code: string | null;
    province: string | null;
    postalAddress2: string | null;
    postalCity: string | null;
    postalCode: string | null;
    postalSuburb: string | null;
  } | null = null;
  accountBanking: {
    bankSwiftNumber: string | null;
    accountNumber: string | null;
  } | null = null;
  accountDocs: {
    proofOfResUploaded: boolean;
    proofOfRegUploaded: boolean;
    regNumber: string | null;
    idNumber: string | null;
    passportNumber: string | null;
    vatNumber: string | null;
    taxNumber: string | null;
  } | null = null;
  countries: CountryCode[] = [];

  get getAddClient(): boolean {
    return this.addClient;
  }
  @Mutation
  setAddClient(val: boolean) {
    this.addClient = val;
  }

  get getEntityId(): string | null {
    return this.entityId;
  }
  @Mutation
  setEntityId(val: string | null) {
    this.entityId = val;
  }

  get getDivisionToBeEdited(): {
    ircCode: string;
    safexCode: string;
    physicalsCode: string;
    subAccount: string;
  } | null {
    return this.divisionToBeEdited;
  }
  @Mutation
  setDivisionsToBeEdited(
    val: {
      ircCode: string;
      safexCode: string;
      physicalsCode: string;
      subAccount: string;
    } | null
  ) {
    this.divisionToBeEdited = val;
  }

  get getAccountDetails(): {
    accountName: string;
    email: string;
    contactNumber: string;
    contactPerson: string;
    contactPersonEmail: string;
    country: string;
    dateOfRegistration: string;
    staffAccount: boolean;
  } | null {
    return this.accountDetails;
  }
  @Mutation
  setAccountDetails(
    val: {
      accountName: string;
      email: string;
      contactNumber: string;
      contactPerson: string;
      contactPersonEmail: string;
      country: string;
      dateOfRegistration: string;
      staffAccount: boolean;
    } | null
  ) {
    this.accountDetails = val;
  }

  get getDivisions(): Division[] {
    return this.divisions;
  }
  @Mutation
  setDivisions(val: Division[]) {
    this.divisions = val;
  }

  get getCountries(): CountryCode[] {
    return this.countries;
  }
  @Mutation
  setCountries(val: CountryCode[]) {
    this.countries = val;
  }

  get getAccountContact(): {
    name: string | null;
    surname: string | null;
    dateOfBirth: string | null;
    email: string | null;
    cellNumber: string | null;
    isCompany: boolean | null;
    companyAddress: string | null;
    postalAddress: string | null;
    streetAddress: string | null;
    streetAddress2: string | null;
    suburb: string | null;
    city: string | null;
    code: string | null;
    province: string | null;
    postalAddress2: string | null;
    postalCity: string | null;
    postalCode: string | null;
    postalSuburb: string | null;
  } | null {
    return this.accountContact;
  }
  @Mutation
  setAccountContact(
    val: {
      name: string | null;
      surname: string | null;
      dateOfBirth: string | null;
      email: string | null;
      cellNumber: string | null;
      isCompany: boolean | null;
      companyAddress: string | null;
      postalAddress: string | null;
      streetAddress: string | null;
      streetAddress2: string | null;
      suburb: string | null;
      city: string | null;
      code: string | null;
      province: string | null;
      postalAddress2: string | null;
      postalCity: string | null;
      postalCode: string | null;
      postalSuburb: string | null;
    } | null
  ) {
    this.accountContact = val;
  }

  get getAccountBanking(): {
    bankSwiftNumber: string | null;
    accountNumber: string | null;
  } | null {
    return this.accountBanking;
  }
  @Mutation
  setAccountBanking(
    val: {
      bankSwiftNumber: string | null;
      accountNumber: string | null;
    } | null
  ) {
    this.accountBanking = val;
  }

  get getAccountDocs(): {
    proofOfResUploaded: boolean;
    proofOfRegUploaded: boolean;
    regNumber: string | null;
    passportNumber: string | null;
    idNumber: string | null;
    vatNumber: string | null;
    taxNumber: string | null;
  } | null {
    return this.accountDocs;
  }
  @Mutation
  setAccountDocs(
    val: {
      proofOfResUploaded: boolean;
      proofOfRegUploaded: boolean;
      regNumber: string | null;
      idNumber: string | null;
      passportNumber: string | null;
      vatNumber: string | null;
      taxNumber: string | null;
    } | null
  ) {
    this.accountDocs = val;
  }

  @Mutation
  resetStore() {
    this.accountBanking = null;
    this.accountContact = null;
    this.accountDetails = null;
    this.accountDocs = null;
    this.divisions = [];
    this.entityId = null;
    this.addClient = true;
    console.log("JSEClient Store Reset.");
  }

  @Action
  async sendToJSE(): Promise<void> {
    try {
      if (this.addClient == true) {
        const payloadAdd = await this.populateAddPayload();

        console.log("Add JSE Client payload", payloadAdd);

        payloadAdd.forEach(async (load) => {
          const resp = await JSEClientHook.addNewClient(load);
          _Vue.prototype.$notificationCreator.createSuccessNotification(
            "Client sent to the JSE."
          );
        });
        //this.resetStore();
      } else {
        const payloadUpdate = await this.populateUpdatePayload();

        console.log("Update JSE Client payload", payloadUpdate);

        if (payloadUpdate != null) {
          const resp = await JSEClientHook.updateClientDetails(payloadUpdate);
          _Vue.prototype.$notificationCreator.createSuccessNotification(
            "Updated Client sent to the JSE."
          );
        }

        //this.resetStore();
      }

      return Promise.resolve();
    } catch (err) {
      if (typeof err == "string") {
        _Vue.$notificationCreator.createErrorNotification(err);
      }
      return Promise.reject(err);
    }
  }

  @Action
  async sendAdditionalClientsToJSE(divisionsToSend: Division[]): Promise<void> {
    try {
      if (divisionsToSend.length > 0) {
        if (this.divisionToBeEdited && this.divisionToBeEdited.safexCode) {
          const existingClient = await JSEClientHook.getClientDetailsForUpdate(
            this.divisionToBeEdited.safexCode
          );

          if (existingClient && existingClient.clientCode) {
            const payload: JSENewSafexModelcs[] = [];

            divisionsToSend.map((division) => {
              if (division.sendToJSE == true) {
                payload.push({
                  addClientModel: {
                    ...existingClient,
                    principal: "",
                    masterClient: division.safexSubAccount
                      ? division.safexSubAccount
                      : "",
                  },
                  safexDivision: {
                    _id: 0,
                    accountingCode: division.accountingCode,
                    accountingSubAccount: division.accountingSubAccount,
                    clientStatus: 0,
                    enumStatus: (division as any).enumStatus || null,
                    createdBy: null,
                    createdDate: null,
                    discretionaryManaged: division.discretionaryManaged,
                    electronicAccNumber: division.electronicAccNumber,
                    entityId: division.entityId,
                    isBeneficial: division.isBeneficial,
                    jseMarket: division.jseMarket,
                    jseTrader: division.jseTrader,
                    jseTraderId: division.jseTraderId,
                    physicalCode: division.physicalCode,
                    physicalsMarket: division.physicalsMarket,
                    physicalsTrader: division.physicalsTrader,
                    physicalsTraderId: division.physicalsTraderId,
                    safexCode: division.safexCode,
                    safexMarket: division.safexMarket,
                    safexSubAccount: division.safexSubAccount,
                    safexTrader: division.accountingCode,
                    safexTraderId: division.safexTraderId,
                    updateDate: null,
                    updatedBy: null,
                  },
                });
              }
            });

            console.log("Additional JSE Client payload", payload);

            payload.forEach(async (load) => {
              const resp = await JSEClientHook.addNewClient(load);
              _Vue.prototype.$notificationCreator.createSuccessNotification(
                "Client sent to the JSE."
              );
            });
          } else {
            throw "Unable to add new clients. Existing client details are not found in the JSE's records.";
          }
        } else {
          throw "Existing division not found.";
        }
      } else {
        throw "No divisions to send to the JSE.";
      }
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @Action
  async populateAddPayload(): Promise<JSENewSafexModelcs[]> {
    try {
      const payload: JSENewSafexModelcs[] = [];
      console.log(
        "JSE checks ",
        this.accountBanking,
        this.accountContact,
        this.accountDetails,
        this.accountDocs,
        this.entityId
      );
      if (
        this.accountBanking &&
        this.accountContact &&
        this.accountDetails &&
        this.accountDocs &&
        this.entityId
      ) {
        this.divisions.map((division) => {
          if (division.sendToJSE == true) {
            const foundCountry = this.countries.find(
              (country) =>
                country.countryName!.replace(/\s+/g, "").toLowerCase() ==
                this.accountDetails!.country.replace(/\s+/g, "").toLowerCase()
            );

            payload.push({
              addClientModel: {
                altTelephone: "",
                bankAccNumber: this.accountBanking!.accountNumber
                  ? this.accountBanking!.accountNumber
                  : "",
                birthDay: this.accountContact!.dateOfBirth
                  ? this.accountContact!.dateOfBirth
                  : "",
                canChangeMarginMult: false,
                complianceName: "",
                countrySeq: foundCountry
                  ? foundCountry.countrySeq
                    ? foundCountry.countrySeq
                    : 0
                  : 0,
                dealer: division.dealer,
                discretionaryManaged: division.discretionaryManaged,
                electronicAccNumber: division.electronicAccNumber
                  ? division.electronicAccNumber
                  : "",
                electronicNotices: false,
                email: this.accountDetails!.email
                  ? this.accountDetails!.email
                  : "",
                fax: this.accountDetails!.contactNumber
                  ? this.accountDetails!.contactNumber
                  : "",
                idNumber: this.accountDocs!.idNumber
                  ? this.accountDocs!.idNumber
                  : "",
                incomeTaxNumber: this.accountDocs!.taxNumber
                  ? this.accountDocs!.taxNumber
                  : "",
                individual:
                  this.entityId && this.entityId.includes("CRM") ? true : false,
                isBeneficial: division.isBeneficial,
                member: division.branch,
                multiplicationFactor: 100,
                name: this.accountDetails!.accountName
                  ? this.accountDetails!.accountName
                  : "",
                passportNumber: this.accountDocs!.passportNumber
                  ? this.accountDocs!.passportNumber
                  : "",
                physicalAddress1: this.accountContact!.streetAddress
                  ? this.accountContact!.streetAddress
                  : "",
                physicalAddress2: this.accountContact!.streetAddress2
                  ? this.accountContact!.streetAddress2
                  : "",
                physicalCity: this.accountContact!.city
                  ? this.accountContact!.city
                  : "",
                physicalCode: this.accountContact!.code
                  ? this.accountContact!.code
                  : "",
                physicalSuburb: this.accountContact!.suburb
                  ? this.accountContact!.suburb
                  : "",
                postalAddress1: this.accountContact!.postalAddress
                  ? this.accountContact!.postalAddress
                  : "",
                postalAddress2: this.accountContact!.postalAddress2
                  ? this.accountContact!.postalAddress2
                  : "",
                postalCity: this.accountContact!.postalCity
                  ? this.accountContact!.postalCity
                  : "",
                postalCode: this.accountContact!.postalCode
                  ? this.accountContact!.postalCode
                  : "",
                postalSuburb: this.accountContact!.postalSuburb
                  ? this.accountContact!.postalSuburb
                  : "",
                principal: "",
                proofOfRegistration: this.accountDocs!.proofOfRegUploaded,
                proofOfResidence: this.accountDocs!.proofOfResUploaded,
                regNumber: this.accountDocs!.regNumber
                  ? this.accountDocs!.regNumber
                  : "",
                secondName: "",
                staffAccount: this.accountDetails!.staffAccount,
                swiftCode: this.accountBanking!.bankSwiftNumber
                  ? this.accountBanking!.bankSwiftNumber
                  : "",
                telephone: this.accountDetails!.contactNumber
                  ? this.accountDetails!.contactNumber
                  : "",
                update: false,
                vatRegNumber: this.accountDocs!.vatNumber
                  ? this.accountDocs!.vatNumber
                  : "",
                masterClient: division.safexSubAccount
                  ? division.safexSubAccount
                  : "",
              },
              safexDivision: {
                _id: 0,
                accountingCode: division.accountingCode,
                accountingSubAccount: division.accountingSubAccount,
                clientStatus: 0,
                enumStatus: (division as any).enumStatus || null,
                createdBy: null,
                createdDate: null,
                discretionaryManaged: division.discretionaryManaged,
                electronicAccNumber: division.electronicAccNumber,
                entityId: division.entityId,
                isBeneficial: division.isBeneficial,
                jseMarket: division.jseMarket,
                jseTrader: division.jseTrader,
                jseTraderId: division.jseTraderId,
                physicalCode: division.physicalCode,
                physicalsMarket: division.physicalsMarket,
                physicalsTrader: division.physicalsTrader,
                physicalsTraderId: division.physicalsTraderId,
                safexCode: division.safexCode,
                safexMarket: division.safexMarket,
                safexSubAccount: division.safexSubAccount,
                safexTrader: division.accountingCode,
                safexTraderId: division.safexTraderId,
                updateDate: null,
                updatedBy: null,
              },
            });
          }
        });
      }
      return Promise.resolve(payload);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  @Action
  async populateUpdatePayload(): Promise<UpdateClient | null> {
    try {
      let payload: UpdateClient | null = null;

      let clientCode: string | null = null;
      if (this.divisions.length > 0) {
        clientCode = this.divisions[0].safexCode;
      } else {
        clientCode = this.divisionToBeEdited!.safexCode;
      }
      const existingClient = await JSEClientHook.getClientDetailsForUpdate(
        clientCode!
      );

      if (existingClient && existingClient.clientCode) {
        let foundCountry: CountryCode | undefined = undefined;
        if (this.accountDetails && this.accountDetails.country)
          foundCountry = this.countries.find(
            (country) =>
              country.countryName!.replace(/\s+/g, "").toLowerCase() ==
              this.accountDetails!.country.replace(/\s+/g, "").toLowerCase()
          );

        payload = {
          altTelephone: existingClient.altTelephone,
          bankAccNumber: this.accountBanking
            ? this.accountBanking.accountNumber
              ? this.accountBanking.accountNumber
              : existingClient.bankAccNumber
            : existingClient.bankAccNumber,
          birthDay: this.accountContact
            ? this.accountContact.dateOfBirth
              ? this.accountContact.dateOfBirth
              : existingClient.birthDay
            : existingClient.birthDay,
          canChangeMarginMult: existingClient.canChangeMarginMult,
          complianceName: existingClient.complianceName,
          countrySeq: foundCountry
            ? foundCountry.countrySeq
              ? foundCountry.countrySeq
              : existingClient.countrySeq
            : existingClient.countrySeq,
          dealer: this.divisions[0]
            ? this.divisions[0].dealer
            : existingClient.dealer,
          discretionaryManaged: this.divisions[0]
            ? this.divisions[0].discretionaryManaged
            : existingClient.discretionaryManaged,
          electronicAccNumber: this.divisions[0]
            ? this.divisions[0].electronicAccNumber
              ? this.divisions[0].electronicAccNumber
              : existingClient.electronicAccNumber
            : existingClient.electronicAccNumber,
          electronicNotices: existingClient.electronicNotices,
          email: this.accountDetails
            ? this.accountDetails.email
              ? this.accountDetails.email
              : existingClient.email
            : existingClient.email,
          fax: this.accountDetails
            ? this.accountDetails.contactNumber
              ? this.accountDetails.contactNumber
              : existingClient.fax
            : existingClient.fax,
          idNumber: this.accountDocs
            ? this.accountDocs.idNumber
              ? this.accountDocs.idNumber
              : existingClient.idNumber
            : existingClient.idNumber,
          incomeTaxNumber: this.accountDocs
            ? this.accountDocs.taxNumber
              ? this.accountDocs.taxNumber
              : existingClient.incomeTaxNumber
            : existingClient.incomeTaxNumber,
          individual:
            this.entityId && this.entityId.includes("CRM") ? true : false,
          isBeneficial: this.divisions[0]
            ? this.divisions[0].isBeneficial
            : existingClient.isBeneficial,
          member: this.divisions[0]
            ? this.divisions[0].branch
            : existingClient.member,
          multiplicationFactor: existingClient.multiplicationFactor,
          name: this.accountDetails
            ? this.accountDetails.accountName
              ? this.accountDetails.accountName
              : existingClient.name
            : existingClient.name,
          passportNumber: this.accountDocs
            ? this.accountDocs.passportNumber
              ? this.accountDocs.passportNumber
              : existingClient.passportNumber
            : existingClient.passportNumber,
          physicalAddress1: this.accountContact
            ? this.accountContact.streetAddress
              ? this.accountContact.streetAddress
              : existingClient.physicalAddress1
            : existingClient.physicalAddress1,
          physicalAddress2: this.accountContact
            ? this.accountContact.streetAddress2
              ? this.accountContact.streetAddress2
              : existingClient.physicalAddress2
            : existingClient.physicalAddress2,
          physicalCity: this.accountContact
            ? this.accountContact.city
              ? this.accountContact.city
              : existingClient.physicalCity
            : existingClient.physicalCity,
          physicalCode: this.accountContact
            ? this.accountContact.code
              ? this.accountContact.code
              : existingClient.physicalCode
            : existingClient.physicalCode,
          physicalSuburb: this.accountContact
            ? this.accountContact.suburb
              ? this.accountContact.suburb
              : existingClient.physicalSuburb
            : existingClient.physicalSuburb,
          postalAddress1: this.accountContact
            ? this.accountContact.postalAddress
              ? this.accountContact.postalAddress
              : existingClient.postalAddress1
            : existingClient.postalAddress1,
          postalAddress2: this.accountContact
            ? this.accountContact.postalAddress2
              ? this.accountContact.postalAddress2
              : existingClient.postalAddress2
            : existingClient.postalAddress2,
          postalCity: this.accountContact
            ? this.accountContact.postalCity
              ? this.accountContact.postalCity
              : existingClient.postalCity
            : existingClient.postalCity,
          postalCode: this.accountContact
            ? this.accountContact.postalCode
              ? this.accountContact.postalCode
              : existingClient.postalCode
            : existingClient.postalCode,
          postalSuburb: this.accountContact
            ? this.accountContact.postalSuburb
              ? this.accountContact.postalSuburb
              : existingClient.postalSuburb
            : existingClient.postalSuburb,
          principal: this.divisions[0]
            ? this.divisions[0].safexCode
              ? this.divisions[0].safexCode
              : existingClient.principal
            : existingClient.principal,
          proofOfRegistration: this.accountDocs
            ? this.accountDocs.proofOfRegUploaded
            : existingClient.proofOfRegistration,
          proofOfResidence: this.accountDocs
            ? this.accountDocs.proofOfResUploaded
            : existingClient.proofOfResidence,
          regNumber: this.accountDocs
            ? this.accountDocs.regNumber
              ? this.accountDocs.regNumber
              : existingClient.regNumber
            : existingClient.regNumber,
          secondName: existingClient.secondName,
          staffAccount: this.accountDetails
            ? this.accountDetails.staffAccount
            : existingClient.staffAccount,
          swiftCode: this.accountBanking
            ? this.accountBanking.bankSwiftNumber
              ? this.accountBanking.bankSwiftNumber
              : existingClient.swiftCode
            : existingClient.swiftCode,
          telephone: this.accountDetails
            ? this.accountDetails.contactNumber
              ? this.accountDetails.contactNumber
              : existingClient.telephone
            : existingClient.telephone,
          update: true,
          vatRegNumber: this.accountDocs
            ? this.accountDocs.vatNumber
              ? this.accountDocs.vatNumber
              : existingClient.vatRegNumber
            : existingClient.vatRegNumber,
          masterClient: this.divisions[0]
            ? this.divisions[0].safexSubAccount
              ? this.divisions[0].safexSubAccount
              : existingClient.masterClient
            : existingClient.masterClient,
          clientCode: existingClient.clientCode,
          clientSeq: existingClient.clientSeq,
          masterClientSeq: existingClient.masterClientSeq,
          memberSeq: existingClient.memberSeq,
        };
      } else {
        console.error(
          "Unable to send this update to the JSE, the client does not exist in the JSE's records.",
          existingClient
        );
        throw "Unable to send this update to the JSE, the client does not exist in the JSE's records.";
      }

      return Promise.resolve(payload);
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
export default getModule(JSEClients);
