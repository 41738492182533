export enum VerifiedClientEnum {
  UnVerified = 0,
  Verified = 1,
  ClosedAccount = 2,
  AwaitingAccount = 3,
  SuspendAccount = 4,
}

export enum EnumStatus {
  Awaiting = 1,
  Approved = 2,
  Suspended = 3,
}
